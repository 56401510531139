<template>
  <div>
    <div class="content-header row">
      <div class="content-header-left col-md-6 col-12 mb-2">
        <div class="row breadcrumbs-top">
          <h2 class="content-header-title float-start mb-0">Booking Trash</h2>
        </div>
      </div>
      <div class="content-header-right col-md-6 col-12 mb-2">
        <div class="col-12">
          <div class="breadcrumb-wrapper">
            <ol class="breadcrumb float-end">
              <li class="breadcrumb-item">
                <router-link to="/dashboard">Dashboard</router-link>
              </li>
              <li class="breadcrumb-item active">Booking Trash</li>
            </ol>
          </div>
        </div>
      </div>
    </div>
    <div class="content-body">
      <section id="dashboard-ecommerce">
        <div class="row match-height">
          <div class="col-xl-12 col-md-12 col-12">
            <div class="card card-congratulation-medal">
              <div class="card-body">
                <section id="ajax-datatable">
                  <div class="row">
                    <div class="col-12">
                      <div class="card table_scoll">
                        <div class="card-datatable">
                          <table
                            class="dt-column-search table table-responsive"
                          >
                            <thead>
                              <tr>
                                <template v-for="head in header" :key="head">
                                  <th>{{ head }}</th>
                                </template>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-show="list.length > 0"
                                v-for="li in list"
                                :key="li.id"
                              >
                                <td style="width: 130px">
                                  <span v-if="li.booking">{{ li.booking?.booking_date }}</span>
                                </td>
                                <!-- <td style="width: 180px">{{ li.booking_id }}</td> -->
                                <td style="width: 140px" class="text-success">
                                  <span v-if="li.booking">{{ li.booking?.check_in_date }}</span>
                                </td>
                                <td style="width: 140px" class="text-danger">
                                  <span v-if="li.booking">{{ li.booking?.check_out_date }}</span>
                                </td>
                                <td class="text-capitalize">
                                  <span v-if="li.booking && li.booking.villa_detail">{{ fullname(li.booking.villa_detail) }}</span>
                                </td>
                                <td class="text-capitalize">
                                  <span v-if="li.booking && li.booking.villa_detail">{{ li.booking.villa_detail.mobile }}</span>
                                </td>
                                <td><span v-if="li.booking">{{ li.booking.amount }}</span></td>
                                <td><span v-if="li.booking">{{ li.booking.discount_amount }}</span></td>
                                <td style="width: 120px">
                                  <span v-if="li.booking">
                                  {{
                                    parseFloat(
                                      li.booking.amount - li.booking.amount * (100 / (100 + 18))
                                    ).toFixed(2)
                                  }}
                                  </span>
                                </td>
                                <td><span v-if="li.booking">{{ li.booking.final_amount }}</span></td>
                                <td style="width: 230px">
                                  <div class="text-success">
                                    <span v-if="li.booking">{{ getBookingType(li.booking.type, li.booking) }}</span>
                                  </div>
                                </td>
                                <td style="width: 150px">
                                  <span v-if="li.booking">{{ getPayment(li.booking) }}</span>
                                </td>
                                <td style="width: 110px"
                                  :class="
                                    recordId === li.id ? `statusZindex` : ``
                                  "
                                >
                                  <span
                                    class="status-badge badge"
                                    :class="getStatus(li.status)[0]"
                                  >
                                    {{ getStatus(li.status)[1] }}
                                  </span>
                                </td>
                                <td style="width: 100px">
                                  <div class="d-flex flex-end">
                                  <button
                                    class="btn btn-sm btn-gradient-primary float-end"
                                    @click="restoreBooking(li.id)"
                                  >
                                    <vue-feather type="rotate-ccw"></vue-feather>
                                  </button>
                                  </div>
                                </td>
                              </tr>
                              <tr v-show="list.length === 0">
                                <td colspan="16" class="text-center">
                                  No Data Found
                                </td>
                              </tr>
                            </tbody>
                            <tfoot>
                              <tr>
                                <template v-for="head in header" :key="head">
                                  <th>{{ head }}</th>
                                </template>
                              </tr>
                            </tfoot>
                          </table>
                          <div class="row d-flex" v-if="paginationData">
                            <div class="col-12 d-flex">
                              <div class="col-6 showing_entry">
                                Showing {{ paginationData.from }} to
                                {{ paginationData.to }} of
                                {{ paginationData.total }} entries
                              </div>
                              <div class="col-6">
                                <Pagination
                                  :data="paginationData"
                                  @pagination-change-page="getResults"
                                  show-disabled="true"
                                  limit="3"
                                  keepLength="false"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import LaravelVuePagination from "laravel-vue-pagination";
// import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import Form from "vform";
import { ref } from "vue";
import moment from "moment";
// import Select2 from "vue3-select2-component";

export default {
  name: "BookingList",
  components: {
    Pagination: LaravelVuePagination,
    // Datepicker,
    // Select2,
  },
  data() {
    return {
      header: [],
      list: [],
      paginationData: {},
      form: new Form({
        rangeDate: ref(),
        from_date: "",
        to_date: "",
        search: null,
        booking_type: null,
        booking_status: null,
      }),
      booking_type_options: [
        {
          id: "All",
          text: "All",
        },
        {
          id: "OneDayPicnic",
          text: "Picnic",
        },
        {
          id: "EventBooking",
          text: "Event",
        },
        {
          id: "Cottage",
          text: "Cottage",
        },
        {
          id: "Platinum Villa",
          text: "Platinum Villa",
        },
        {
          id: "Diamond Villa",
          text: "Diamond Villa",
        },
      ],
      booking_status_options: [
        {
          id: "All",
          text: "All",
        },
        {
          id: "Success",
          text: "Success",
        },
        {
          id: "Pending",
          text: "Pending",
        },
      ],
      booking_details: {},
      token: localStorage.getItem("auth_token"),
      loader: false,
      page: 1,
    };
  },
  async mounted() {
    this.header = [
      "Date",
      // "Booking Id",
      "Check in",
      "Check out",
      "Name",
      "Mobile No.",
      "Amount",
      "Discount",
      "18% GST",
      "Final",
      "Booking Type",
      "Payment Type",
      "Status",
      "",
    ];
    this.getResults();
  },
  methods: {
    async getBookingDetails(booking_id) {
      this.loader = true;
      await axios
        .get(
          process.env.VUE_APP_API_URL +
            "/booking/details?villa_booking_id=" +
            booking_id,
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${this.token}`,
            },
          }
        )
        .then((response) => {
          this.booking_details = response.data.data;
          this.booking_details.title = "Villa Booking";
          if (response.data.data.picnic) {
            this.booking_details.title = response.data.data.picnic.pack_name;
          }
          if (response.data.data.event) {
            this.booking_details.title = response.data.data.event.name;
          }
          setTimeout(() => {
            this.loader = false;
          }, 500);
        });
    },
    async exportReport() {
      var page = this.page;
      var searchq = "";
      if (this.form.search) {
        searchq += "&search=" + this.form.search;
      }
      if (this.form.from_date) {
        searchq += "&from_date=" + this.form.from_date;
      }
      if (this.form.to_date && this.form.from_date != this.form.to_date) {
        searchq += "&to_date=" + this.form.to_date;
      }
      if (this.form.booking_type) {
        searchq += "&booking_type=" + this.form.booking_type;
      }
      if (this.form.booking_status) {
        searchq += "&booking_status=" + this.form.booking_status;
      }

      await axios
        .get(
          process.env.VUE_APP_API_URL +
            "/booking/booking-export?page=" +
            page +
            searchq +
            "&type=",
          {
            headers: {
              Authorization: `Bearer ${this.token}`,
              "Content-Type": "blob",
            },
            responseType: "arraybuffer",
          }
        )
        .then((response) => {
          console.log(response);
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.setAttribute("download", "BookingsReport.xlsx");
          document.body.appendChild(fileLink);
          fileLink.click();
        });
    },
    dateChange() {
      this.form.from_date = "";
      this.form.to_date = "";
      if (this.form.rangeDate) {
        const date = this.form.rangeDate;
        if (date.length > 0 && date.length != undefined) {
          date.map(() => {
            this.form.from_date = moment(date[0]).format("YYYY-MM-DD");
            this.form.to_date = moment(date[1]).format("YYYY-MM-DD");
          });
        } else {
          if (typeof date != undefined) {
            this.form.from_date = moment(date).format("YYYY-MM-DD");
            this.form.to_date = moment(date).format("YYYY-MM-DD");
          }
        }
      }
      this.getResults();
    },
    async getResults(page = 1) {
      this.page = page;
      var searchq = "";
      
      if (this.form.search) {
        searchq += "&search=" + this.form.search;
      }
      if (this.form.from_date) {
        searchq += "&from_date=" + this.form.from_date;
      }
      if (this.form.to_date && this.form.from_date != this.form.to_date) {
        searchq += "&to_date=" + this.form.to_date;
      }
      if (this.form.booking_type) {
        searchq += "&booking_type=" + this.form.booking_type;
      }
      if (this.form.booking_status) {
        searchq += "&booking_status=" + this.form.booking_status;
      }

      await axios
        .get(
          process.env.VUE_APP_API_URL +
            "/booking/trash?page=" +
            page +
            searchq +
            "&type=",
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${this.token}`,
            },
          }
        )
        .then((response) => {
          this.paginationData = response.data.data;
        });
      this.list = this.paginationData.data;
    },
    getStatus(status) {
      if (status == 0) {
        return [`badge-light-primary`, `Progress`];
      } else if (status == 1) {
        return [`badge-light-success`, `Success`];
      } else if (status == 2) {
        return [`badge-light-warning`, `Pending`];
      } else if (status == 3) {
        return [`badge-light-danger`, `Cancel`];
      } else if (status == 4) {
        return [`badge-light-info`, `Failed`];
      } else if (status == 5) {
        return [`badge-light-warning`, `Pending`];
      }
    },
    getBookingType(bookingType = "Villa", item) {
      if (bookingType == "EventBooking") {
        return "Event";
      } else if (bookingType == "OneDayPicnic") {
        return "Picnic";
      } else {
        var bookingTypeArr = [];
        var bookingTypeList = "";
        item?.villa_booking_data.forEach((element) => {
          if (element.villa_type_master) {
            bookingTypeArr.push(
              element.villa_type_master.villa_name
                .toLowerCase()[0]
                .toUpperCase() +
                element.villa_type_master.villa_name
                  .toLowerCase()
                  .replace(" villa", "")
                  .slice(1)
            );
          }
        });

        // console.log(bookingTypeArr);

        var uniqueNames = bookingTypeArr.filter(
          (item, index) => bookingTypeArr.indexOf(item) === index
        );

        uniqueNames.forEach((element) => {
          bookingTypeList +=
            element.toLowerCase()[0].toUpperCase() +
            element.toLowerCase().replace(" villa", "").slice(1) +
            " | ";
        });

        return bookingTypeList.slice(0, -3);
      }
    },
    getPayment(item) {
      if (item.payment_mode_id == 1) {
        return "CASH";
      } else if (item.payment_mode_id == 2) {
        return "ONLINE";
      }
    },
    fullname(item) {
      const fullnamestring = item
        ? item.first_name + " " + (item.last_name ? " " + item.last_name : "")
        : "";

      return fullnamestring.toLowerCase();
    },
    async edit(id) {
      this.disabled = false;
      this.showTable = false;
      this.showForm = true;
      this.showCancel = true;
      this.recordId = 0;
      const output = await axios.get(
        process.env.VUE_APP_API_URL + "/booking/villa" + (id ? "/" + id : ""),
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${this.token}`,
          },
        }
      );
      this.form.GST_amount = output.data.data.GST_amount;
      this.form.final_amount = output.data.data.final_amount;
      this.form.amount = output.data.data.amount;
      this.form.extra_charge = output.data.data.extra_charge;
      // this.form.booking_cancel = output.data.data.booking_cancel;
      this.form.rangeDate = [
        output.data.data.check_in_date,
        output.data.data.check_out_date,
      ];
      this.form.coupon_code = output.data.data.coupon_code;
      this.form.discount_amount = output.data.data.discount_amount;
      this.form.id = output.data.data.id;
      this.form.payment_mode_id = output.data.data.payment_mode_id;
      this.form.total_adult = output.data.data.total_adult;
      this.form.total_child = output.data.data.total_child;
      this.form.persons = output.data.data.villa_edit;
      this.form.total_villa = output.data.data.total_villa;

      var arr = output.data.data.villa_edit;
      arr.map((value) => {
        this.villa_type_id[value.villa_type_id] = true;
        this.show_villa[value.villa_type_id] = true;
      });
    },
    async searchData() {
      setTimeout(() => {
        this.getResults();
      }, 300);
    },
    restoreBooking(id) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, restore it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ms-1",
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          axios
            .post(process.env.VUE_APP_API_URL + "/booking-restore",
              {
                "id": id,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  "Accept": "application/json",
                  "Authorization": `Bearer ${this.token}`
                },
              }
            )
            .then(response => {
              this.getResults();
              if (response.data.status) {
                this.$toast.success(response.data.message);
              } else {
                this.$toast.error(response.data.error?.[0]);
              }
            })
            .catch((error) => {
              this.$toast.error(error);
            });
        }
      });
    },
  },
};
</script>
<style scoped>
/* .card-datatable {
  overflow-x: auto;
} */

.table > :not(caption) > * > * {
  padding: 0.72rem 1rem !important;
}
.loader-wrapper {
  height: 8em;
  align-items: center;
  display: flex;
}
.remove_padding {
  padding: 0;
}
.member .list {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

.member .heading {
  margin-bottom: 10px;
  display: block;
  font-size: 15px;
}

.member .list li {
  display: flex;
  align-items: center;
}

.member .list li .icon {
  margin-right: 15px;
}

.member .list li .icon .vue-feather {
  width: 15px;
}

.member .list li .text {
  font-size: 14px;
  color: #000000b5;
  text-transform: capitalize;
}
.detail_card_wrapper {
  margin: 10px;
}
.detail_card {
  width: 100%;
  text-align: left;
  background-color: #fff;
  padding: 15px 20px;
  box-shadow: 0px 0px 60px #d3e5e9;
  border-radius: 10px;
  height: -moz-fit-content;
  height: fit-content;
}

.heading_other {
  margin-bottom: 10px;
  display: block;
  font-size: 15px;
  padding-left: 0;
  color: #42aea3;
}

.detail_card.invert {
  background-color: transparent;
  box-shadow: none;
  border: 1px solid #42aea3;
  margin-bottom: 10px;
}

.detail_card.mb {
  margin-bottom: 20px;
}

.detail_card .check_in_out_box {
  display: flex;
}

.detail_card .check_in_out_box.check_in_out_box_bottom {
  padding-bottom: 0 !important;
}

.detail_card .check_in_out_box .check_in_box:nth-child(1) {
  border-right: 1px solid #e9e9e9;
  padding-right: 15px;
  /* margin-right: 15px; */
}

.detail_card .check_in_out_box .check_in_box:nth-child(2) {
  padding-left: 20px !important;
}

.detail_card .check_in_box {
  padding-bottom: 5px;
}

.detail_card .check_in_box {
  width: 50%;
}

.detail_card .check_in_title {
  font-size: 15px;
  font-weight: 500;
  padding-bottom: 7px;
}

.detail_card .check_in_text {
  font-size: 18px;
  font-weight: 500;
  color: #42aea3;
}

.product-box {
  border: 1px solid #ebe9f1;
  border-radius: 0.357rem;
}
.btn-add-new span {
  margin-top: 5px;
}
.btn-add-new {
  display: flex;
}
.vue-feather--plus {
  margin: 2px 3px 0px 0px;
}
.img-view {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}
.trash-view {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}
.trash-view i {
  color: #fff;
  transition: all 0.2s ease;
  background-image: linear-gradient(47deg, #ea5455, #f08182);
  background-repeat: repeat;
  padding: 0.715rem 0.736rem;
  border-radius: 0.358rem;
  cursor: pointer;
}
.trash-view .disable {
  background-image: linear-gradient(47deg, #302a2a, #adabab) !important;
}
.trash-view i:hover {
  transform: translateY(-2px);
}
.sectionBlock {
  box-shadow: 6px 6px 25px -8px #9e9e9e;
}
.error {
  color: #ea5455;
  font-size: 0.857rem;
}
.form-check {
  padding-left: 0.785rem !important;
}
.status-badge {
  position: relative;
  cursor: pointer;
}
.statusDiv {
  position: absolute;
  box-shadow: 6px 6px 25px -8px #9e9e9e;
  padding: 5px;
  border-radius: 10px;
  z-index: 9999;
  background-color: #fff;
}
.statusDiv ul,
.mobileDiv ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0 !important;
}
.statusDiv ul li,
.mobileDiv ul li {
  padding: 5px;
  cursor: pointer;
}
.statusDiv ul li:hover,
.mobileDiv ul li:hover {
  background-color: #e3e0ff;
  font-weight: bold;
  border-radius: 5px;
}
.statusZindex {
  z-index: -1;
}
.selectioBoxOther {
  box-shadow: 2px -12px 16px -8px #dbdbdb;
}
.mobileSearchDiv {
  position: relative;
}
.mobileDiv {
  position: absolute;
  padding: 5px 10px;
  border-radius: 10px !important;
  z-index: 9999;
  top: 50px;
  height: auto;
  max-height: 200px;
  width: 100%;
  overflow-y: auto;
  transition: all 1s;
  background-color: #fff;
  box-shadow: 1px 12px 25px 4px #9e9e9e !important;
}
.search-mobile {
  border-right: 1px solid #d8d6de !important;
  border-top-right-radius: 0.357rem !important;
  border-bottom-right-radius: 0.357rem !important;
}
.search-mobile:focus {
  border-color: #7367f0 !important;
}
/* .card-body, .content, .sectionBlock, input, select, span, .villaCls{
    background-color: #302a2a !important;
    color: #adabab !important; 
  } */

.modal-body {
  background: #f7fffeff;
}

.tile-group {
  padding: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.tile-group .icon {
  padding: 20px;
  background: #cdf6e7;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tile-group .icon img {
  width: 70px;
  height: 70px;
  transition: all 0.3s ease-in-out;
  transform: rotate3d(0, 0, 0, 0deg);
}

.tile-group .title {
  font-size: 18px;
  padding: 10px 0;
}

.tile-group:hover > .icon img {
  transform: rotate(360deg);
  transform: rotate3d(0, 1, 0, 180deg);
}

.ecommerce-application .search-product,
.ecommerce-application .input-group-text {
  height: 39px !important;
}

.ecommerce-application .dp__main:deep(.dp__input) {
  height: 39px;
}

button.export_csv {
  margin-right: 10px;
}

.col-sm-6, .col-sm-4 {
  margin-top: 10px;
}

.mr-1 {
  margin-right: 15px;
}

.flex-end {
  justify-content: end;
}
</style>
